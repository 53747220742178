import { Box, Image, Main, ResponsiveContext } from "grommet";
import React from "react";
import styled from "styled-components";
import LoadingView from "../components/loading";
import PaymentForm from "../components/payment-form";
import SiteLayout from "../layout";
import { enqiry } from "../services/helpers";
export default function Trans(props) {
  const size = React.useContext(ResponsiveContext);
  let transId = props.location.pathname.replace("/t/", "");
  transId = transId.replace("/", "");
  const [mgs, setMgs] = React.useState({});
  const [upg, setUpg] = React.useState({});
  const [trans, setTrans] = React.useState({});
  const [merchant, setMerchant] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);``

  React.useEffect(() => {
    const jsScript = document.createElement("script");
    // Test UPG Url
    // jsScript.src =
    //   'https://upgstaglightbox.egyptianbanks.com/js/Lightbox.js'

    // Live UPG Url
    // jsScript.src = "https://upglightbox.egyptianbanks.com/Lightbox.js";
    jsScript.src =
    "https://banquemisr.gateway.mastercard.com/checkout/version/57/checkout.js";

    jsScript.setAttribute(
      "data-error",
      process.env.GATSBY_GATEWAY_URL + "/api/v2/errorcallback/"
    );
    jsScript.setAttribute(
      "complete",
      process.env.GATSBY_GATEWAY_URL + "/api/trans/mgs/callback"
    ); 

    jsScript.async = true;
    setTimeout(() => {
      document.body.appendChild(jsScript);
    }, 100);
  }, []);

  React.useEffect(() => {
    const jsScript = document.createElement("script");
    jsScript.src =
      "https://banquemisr.gateway.mastercard.com/checkout/version/57/checkout.js";
     //"https://qnbalahli.test.gateway.mastercard.com/checkout/version/51/checkout.js";
    // "https://qnbalahli.test.gateway.mastercard.com/checkout/version/43/checkout.js";
  jsScript.setAttribute(
      "data-error",
      process.env.GATSBY_GATEWAY_URL + "/api/v2/errorcallback/"
    );
    jsScript.setAttribute(
      "complete",
      process.env.GATSBY_GATEWAY_URL + "/api/trans/mgs/callback"
    ); 
    jsScript.async = true;
    setTimeout(() => {
      document.body.appendChild(jsScript);
    }, 100);
  }, []);

  React.useEffect(() => {
    document.getElementById("preloading").innerHTML = "";
  });

  React.useEffect(() => {
    async function fetchMyAPI() {
      try {
        let response = await enqiry(transId);
        if (response.data.status === 200) {
          setMgs(response.data.data.mgs);
          setUpg(response.data.data.upg);
          setTrans(response.data.data.transaction);
          setMerchant(response.data.data.merchant);
        } else {
          // window.location = "/error?e=" + response.data.error;
        }

        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log("error", e);
        // window.location = "/error?e=" + e.message;
      }
    }

    fetchMyAPI();
  }, [transId]);

  return (
    <SiteLayout>
      {loading ? (
        <LoadingView />
      ) : (
        <WrapConontroller>
          <Main direction="row">
            <Box
              width={size === "small" ? "100%" : "580px"}
              pad={{ left: "medium" }}
              // overflow={{ horizontal: "hidden", vertical: "scroll" }}
            >
              <Box justify="center" align="start" pad="small" height="80px">
                <Image src={require("../images/logo.svg")} />
              </Box>
              <PaymentForm
                mgs={mgs}
                upg={upg}
                trans={trans}
                merchant={merchant}
                onSuccess={() => setSuccess()}
              />
            </Box>
            {size !== "small" && (
              <Box flex={{ grow: 2 }} className="controlDiv">
                <Image
                  src={require("../images/elipse-background.svg")}
                  className="controlElipse"
                />
                <Box
                  className="controlIcon"
                  fill
                  justify="center"
                  align="center"
                >
                  <Image src={require("../images/mobile-payment.svg")} />
                </Box>
              </Box>
            )}
          </Main>
        </WrapConontroller>
      )}
    </SiteLayout>
  );
}

const WrapConontroller = styled.div`
  min-height: 100vh;
  .controlDiv {
    position: relative;
    overflow: hidden;
    .controlElipse {
      position: absolute;
      width: calc(100vh + 1000px);
      bottom: -250px;
      left: 10px;
      max-width: initial;
      z-index: 100;
    }
    .controlIcon {
      position: absolute;
      z-index: 101;
      left: 150px;
      min-width: 300px;
    }
  }
`;
