import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  Heading,
  Image,
  Select,
  Text,
} from "grommet";
import React from "react";
import MgsForm from "./mgs-form";
import PaidTrans from "./paid-trans";
import TransInfo from "./trans-info";
import UpgForm from "./upg-form";
import WalletForm from "./wallet-form";
import ReferenceForm from "./reference-form";
let defaultOptions = ["Visa/Master Card", "Meeza"];

export default function PaymentForm({ mgs, upg, trans, merchant }) {
  if (merchant.name == "Health Pay") {
    defaultOptions = ["Visa/Master Card", "Meeza", "Reference"];
  } else if (merchant.name == "Masrpay") {
    defaultOptions = ["Visa/Master Card"];
  }
  const [options, setOptions] = React.useState(defaultOptions);
  const [value, setValue] = React.useState("Visa/Master Card");
  const [success, setSuccess] = React.useState(false);
  const [successRef, setSuccessRef] = React.useState(false);

  const onSuccess = () => {
    setSuccess(true);
  };
  const onSuccessRef = () => {
    setSuccess(true);
    setSuccessRef(true);
  };
  return (
    <>
      <Box fill="vertical">
        <Box
          pad="small"
          // overflow={{ horizontal: "hidden", vertical: "scroll" }}
        >
          {trans.status === "COMPLETED" && (
            <PaidTrans trans={trans} merchant={merchant} />
          )}
          {success ? (
            <Card pad="small" background="light-1" gap="medium">
              <CardBody>
                <Heading level="4" color="status-ok" textAlign="left">
                  You will receive a guiding SMS.
                </Heading>
                Please follow instructions sent over your phone number linked to
                the wallet
              </CardBody>
              <CardFooter>
                <Button
                  margin={{ vertical: "small" }}
                  primary
                  size="medium"
                  label={<Text color="white">Ok</Text>}
                  onClick={() => {
                    if (successRef) {
                      window.location = "/reference-success/" + trans.uid;
                    } else {
                      window.location = "/wallet-success/" + trans.uid;
                    }
                  }}
                />
              </CardFooter>
            </Card>
          ) : (
            <>
              {trans.status === "ACTIVE" && (
                <>
                  <Box pad="small">
                    <TransInfo trans={trans} merchant={merchant} />
                    {upg ? (
                      <>
                        <Text level="3" color="brand" weight="bold">
                          {"How would you like to pay?"}
                        </Text>
                        <Select
                          size="small"
                          placeholder="Select your payment method"
                          value={value}
                          options={options}
                          onChange={({ option }) => setValue(option)}
                          onClose={() => setOptions(defaultOptions)}
                        />
                      </>
                    ) : null}
                  </Box>
                  {value === "Visa/Master Card" && (
                    <MgsForm
                      trans={trans}
                      mgs={mgs}
                      onSuccess={() => onSuccess()}
                    />
                  )}
                  {value === "Meeza" && (
                    <UpgForm
                      trans={trans}
                      mgs={mgs}
                      onSuccess={() => onSuccess()}
                    />
                  )}
                  { value === "Wallet" && (
                    <WalletForm
                      trans={trans}
                      upg={upg}
                      merchant={merchant}
                      onSuccess={() => onSuccess()}
                    />
                  )}
                  {value === "Reference" && (
                    <ReferenceForm
                      reference={trans}
                      merchant={merchant}
                      onSuccess={() => onSuccessRef()}
                    />
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </Box>
      {/* <Box direction="row">
        <Box flex="grow">
          <Link to="/">
            <Anchor as="span">
              <Text size="small" color="light-6">
                Terms & Conditions
              </Text>
            </Anchor>
          </Link>
        </Box>
        <Box flex="grow">
          <Link to="/">
            <Anchor as="span">
              <Text size="small" color="light-6">
                Privacy Policy
              </Text>
            </Anchor>
          </Link>
        </Box>
      </Box> */}
      <Box pad={{ vertical: "medium" }}>
        {/* <Box height="xsmall" width="xsmall">
          <Image
            src={require("../images/opay.png")}
            className="controlElipse"
            fit="contain"
          />
        </Box> */}

        <Text size="small" color="light-6">
          Copyright © {new Date().getFullYear()} Masrpay . All Rights Reserved.
        </Text>
      </Box>
    </>
  );
}
