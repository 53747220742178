// import { Box, Button, Text } from "grommet";
// import React from "react";
// import { createSession } from "../services/helpers";
// import LoadingView from "./loading";

// // export default function UpgForm({ trans, upg, merchant }) {
// //   const [loading, setLoading] = React.useState(false);
  
// //   const initSesssion = () => {
// //     const info = {
// //       OrderId: "",
// //       paymentMethodFromLightBox: 2,
// //       MID: upg.merchant_id,
// //       TID: upg.terminal_id,
// //       SecureHash: upg.secureHash,
// //       TrxDateTime: upg.txDateTime,
// //       AmountTrxn: trans.amount * 100,
// //       MerchantReference: upg.reference_no,
// //       ReturnUrl: merchant.return_url,
// //       completeCallback: function (data) {
// //         window.location.href =
// //           process.env.GATSBY_GATEWAY_URL +
// //           "/api/" +
// //           trans.uid +
// //           "/" +
// //           upg.hmac +
// //           "/" +
// //           upg.txDateTime;
// //       },
// //       errorCallback: function (e) {
// //         if (typeof window !== "undefined") {
// //           window.location = "/error?e=" + e.message;
// //         }
// //       },
// //       cancelCallback: function (res) {
// //         setLoading(false);
// //       },
// //     };
// //     if (window.Lightbox) {
// //       window.Lightbox.Checkout.configure = info;
// //     }
// //     window.Lightbox.Checkout.showLightbox();
// //   };

// //   return (
// //     <>
// //       <Box
// //         pad="small"
// //         // overflow={{ horizontal: "hidden", vertical: "scroll" }}
// //       >
// //         <Text
// //           size="medium"
// //           color="brand"
// //           weight="bold"
// //           margin={{ bottom: "medium", top: "small" }}
// //         >
// //           You can pay using meeza cards
// //         </Text>

// //         <Button
// //           margin={{ vertical: "large" }}
// //           primary
// //           disabled={loading}
// //           size="large"
// //           label={<Text color="white">Pay Now</Text>}
// //           onClick={() => {
// //             setLoading(true);
// //             initSesssion();
// //           }}
// //         />
// //       </Box>
// //     </>
// //   );
// // }


// export default function UpgForm({ trans, mgs }) {
//   const [selectedMgs, setSelectedMgs] = React.useState(null);
//   const [loading, setLoading] = React.useState(false);
//   let mgsArr = [];
//   mgs.forEach((element, index) => {
//     let merchantCred;
//     element.installment_period
//       ? (merchantCred = {
//           label: `${element.name} - installment ${element.installment_period} months`,
//           value: element.name + "_" + index,
//           data: element.name,
//         })
//       : (merchantCred = {
//           label: `${element.name}`,
//           value: element.name + "_" + index,
//           data: element.name,
//         });
//     mgsArr.push(merchantCred);
//   });

//   const initSesssion = async () => {
//     let merchantCred = {};
//     if (mgs.length === 1) {
//       merchantCred = mgs[0];
//     } else {
//       let index = selectedMgs.value.split("_")[1];
//       merchantCred = mgs[index];
//     }

//     try {
//       const session = await createSession(
//         trans.uid,
//         merchantCred.name,
//         merchantCred.installment_period
//       );
//       if (session.data.status === 200) {
//         const sessionData = session.data.data;
//         //console.log( sessionData.session_id );

//         if (window.Checkout) {

//        window.Checkout.configure({
//             session: {
//               id: sessionData.session_id,
//             },
//             interaction: {
//               merchant: {
//                 name: `${sessionData.first_name} ${sessionData.last_name}`,
//                 address: {
//                   line1: "",
//                   line2: "",
//                 },
//               },
//               displayControl: {
//                 orderSummary: "SHOW",
//                 paymentConfirmation: "HIDE",
//                 billingAddress: "HIDE",
//                 customerEmail: "HIDE",
//                 shipping: "HIDE",
//               },
//             },

//           });

//           /*   window.Checkout.configure({
//             merchant: 'TESTQNBAATEST001',
//             order: {
//                 amount: function() {
//                     return '10';
//                 },
//                 currency: 'USD',
//                 description: 'Purchasing Request...',
//                 id: '1040'
//             },
//             session: {
//                 id: sessionData.session_id,
//             },
//             interaction: {
//                 merchant: {
//                     name: 'Test QNB',
//                     address: {
//                         line1: 'Cairo'
//                     },
//                     email: 'hesham.kamal.mp@gmail.com',
//                     phone: '01007523861',
//                     logo: 'https://yourdomian.com/images/logo.png'
//                 },
//                 locale: 'en_US',
//                 theme: 'default',
//             }
//         }); 
        
//             function errorCallback(error) {
//             console.log(error);
//         }
//         function cancelCallback() {
//             console.log('Payment cancelled');
//         }
//         function timeoutCallback() {
//             console.log('Payment Timed Out');
//         }
        
//         */


//           window.Checkout.showPaymentPage();
//           // window.Checkout.showLightbox();
//         }
//       }
//     } catch (e) {
//       if (typeof window !== "undefined") {
//         window.location = "/error?e=" + e.message;
//       }
//     }
//   };

//   return (
//     <>
//       {loading && (
//         <Layer
//           onEsc={() => {
//             // this.setState({ isOnFrame: false });
//           }}
//           onClickOutside={() => {
//             // this.setState({ isOnFrame: false });
//           }}
//           modal
//           style={{ zIndex: 111 }}
//         >
//           <Box
//             width="medium"
//             height="medium"
//             pad={{ vertical: "large", horizontal: "large" }}
//             style={{ zIndex: 99 }}
//           >
//             <LoadingView text={"Loading your secure payment"} />
//           </Box>
//         </Layer>
//       )}
//       <Box pad="small">
//         {mgs.length > 1 ? (
//           <>
//             <Text size="small" color="brand" weight="bold">
//               Notice :
//               <Text size="small" color="black" weight="normal">
//                 {" "}
//                 you can use installment payment if you have credit card from
//                 banquemisr
//               </Text>
//             </Text>
//             <RadioButtonGroup
//               name="radio"
//               pad={{ vertical: "medium" }}
//               options={mgsArr}
//               value={selectedMgs?.label}
//               onChange={(event) => {
//                 setSelectedMgs(event.target);
//               }}
//             />
//           </>
//         ) : (
//           <Text weight="bold" level="4">
//             You can pay using meeza cards
//           </Text>
//         )}
//         <Button
//           margin={{ vertical: "large" }}
//           primary
//           disabled={mgs.length > 1 ? !selectedMgs : null || loading}
//           size="large"
//           label={<Text color="white">Pay Now</Text>}
//           onClick={async () => {
//             setLoading(true);
//             await initSesssion();
//           }}
//         />
//       </Box>
//     </>
//   );
// }

import { Box, Button, RadioButtonGroup, Text, Layer } from "grommet";
import React from "react";
import { createSession } from "../services/helpers";
import LoadingView from "./loading";
export default function UpgForm({ trans, mgs }) {
  const [selectedMgs, setSelectedMgs] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  let mgsArr = [];
  mgs.forEach((element, index) => {
    let merchantCred;
    element.installment_period
      ? (merchantCred = {
          label: `${element.name} - installment ${element.installment_period} months`,
          value: element.name + "_" + index,
          data: element.name,
        })
      : (merchantCred = {
          label: `${element.name}`,
          value: element.name + "_" + index,
          data: element.name,
        });
    mgsArr.push(merchantCred);
  });

  const initSesssion = async () => {
    let merchantCred = {};
    if (mgs.length === 1) {
      merchantCred = mgs[0];
    } else {
      let index = selectedMgs.value.split("_")[1];
      merchantCred = mgs[index];
    }

    try {
      const session = await createSession(
        trans.uid,
        merchantCred.name,
        merchantCred.installment_period
      );
      if (session.data.status === 200) {
        const sessionData = session.data.data;
        //console.log( sessionData.session_id );

        if (window.Checkout) {

       window.Checkout.configure({
            session: {
              id: sessionData.session_id,
            },
            interaction: {
              merchant: {
                name: `${sessionData.first_name} ${sessionData.last_name}`,
                address: {
                  line1: "",
                  line2: "",
                },
              },
              displayControl: {
                orderSummary: "SHOW",
                paymentConfirmation: "HIDE",
                billingAddress: "HIDE",
                customerEmail: "HIDE",
                shipping: "HIDE",
              },
            },

          });

          /*   window.Checkout.configure({
            merchant: 'TESTQNBAATEST001',
            order: {
                amount: function() {
                    return '10';
                },
                currency: 'USD',
                description: 'Purchasing Request...',
                id: '1040'
            },
            session: {
                id: sessionData.session_id,
            },
            interaction: {
                merchant: {
                    name: 'Test QNB',
                    address: {
                        line1: 'Cairo'
                    },
                    email: 'hesham.kamal.mp@gmail.com',
                    phone: '01007523861',
                    logo: 'https://yourdomian.com/images/logo.png'
                },
                locale: 'en_US',
                theme: 'default',
            }
        }); 
        
            function errorCallback(error) {
            console.log(error);
        }
        function cancelCallback() {
            console.log('Payment cancelled');
        }
        function timeoutCallback() {
            console.log('Payment Timed Out');
        }
        
        */


          window.Checkout.showPaymentPage();
          // window.Checkout.showLightbox();
        }
      }
    } catch (e) {
      if (typeof window !== "undefined") {
        window.location = "/error?e=" + e.message;
      }
    }
  };

  return (
    <>
      {loading && (
        <Layer
          onEsc={() => {
            // this.setState({ isOnFrame: false });
          }}
          onClickOutside={() => {
            // this.setState({ isOnFrame: false });
          }}
          modal
          style={{ zIndex: 111 }}
        >
          <Box
            width="medium"
            height="medium"
            pad={{ vertical: "large", horizontal: "large" }}
            style={{ zIndex: 99 }}
          >
            <LoadingView text={"Loading your secure payment"} />
          </Box>
        </Layer>
      )}
      <Box pad="small">
        {mgs.length > 1 ? (
          <>
            <Text size="small" color="brand" weight="bold">
              Notice :
              <Text size="small" color="black" weight="normal">
                {" "}
                you can use installment payment if you have credit card from
                banquemisr
              </Text>
            </Text>
            <RadioButtonGroup
              name="radio"
              pad={{ vertical: "medium" }}
              options={mgsArr}
              value={selectedMgs?.label}
              onChange={(event) => {
                setSelectedMgs(event.target);
              }}
            />
          </>
        ) : (
          <Text weight="bold" level="4">
            You can pay using meeza cards
          </Text>
        )}
        <Button
          margin={{ vertical: "large" }}
          primary
          disabled={mgs.length > 1 ? !selectedMgs : null || loading}
          size="large"
          label={<Text color="white">Pay Now</Text>}
          onClick={async () => {
            setLoading(true);
            await initSesssion();
          }}
        />
      </Box>
    </>
  );
}

